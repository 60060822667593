.header {
    max-width: 1024px;
    margin: 5px auto 0;
    border-bottom: 3px solid $main-color;
    padding: 0 10px;
    &__agent {
        font-size: 1rem;
        padding-right: 10px;
        margin-bottom: 10px;
    }
    &__logo-wrapper {
        display: flex;
    }
    &__logo {
        margin-right: 10px;
        img {
            height: 30px;
        }
    }
    &__catch {
        font-weight: normal;
        font-size: 1rem;
    }
    &__text {
        font-size: 2.8rem;
        margin: 5px 0 10px;
        line-height: 1.2;
        a {
            text-decoration: none;
            color: #333;
        }
    }
    .pc-nav {
        display: none;
    }
    .drawer-menu-item {
        //text-align: center;
        //font-weight: bold;
        //font-size: 1.7rem;
        //padding: 15px 10px;
        //border-bottom: 1px solid #ccc;
    }
}

@media screen and (min-width: $small-pc){
    .header {
        padding: 0;
        &-inner {
            position: relative;
        }
        &__agent {
            text-align: right;
        }
        &__logo-wrapper {
            height: 90px;
        }
        &__logo {
            img {
                height: 75px;
            }
        }
        &__catch {
            font-size: 1.5rem;
            text-align: left;
            line-height: 1;
            br {
                display: none;
            }
        }
        &__text {
            position: absolute;
            top: 50px;
            left: 107px;
            font-size: 3.7rem;
            text-align: left;
            line-height: 1;
            br {
                display: none;
            }
        }

    }
}