.page-header {
    h1 {
        font-size: 2.4rem;
        line-height: 1.3;
    }
    p {
        font-size: 1.3rem;
        color: #666;
    }
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin: 10px 0;
}

@media screen and (min-width: $small-pc) {
    .page-header {
        h1 {
            font-size: 3rem;
            line-height: 1.4;
        }
        p {
            font-size: 1.3rem;
        }
    }
}