
$main-color: #59ABD1;

$simbol-color: #4EB0F7;


$tablet: 600px;
$small-pc: 960px;
$wide-pc: 1280px;


$body-bg: #fff !default;
$text-color: #555 !default;
$link-color: #555 !default;
$link-hover-color: darken($link-color, 5%) !default;
$radius: 8px !default;


$gutter: 40px !default;
$gutter-large: $gutter / 2 !default;

$border-radius: 7px;



@mixin screen-small-pc {
    @media screen and (min-width: $small-pc) {
        @content;
    }
}
