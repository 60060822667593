.drawer-menu {
    &>li {
        border: 1px solid #ccc;
        border-width: 1px 0 0 0;
    }
}
.drawer-menu-item {
    &:hover {
        text-decoration: none;
    }
}
.drawer-menu-item {
    position: relative;
    text-align: center;
    font-weight: bold;
    font-size: 1.7rem;
    padding: 15px 10px;
    &:before {
        @include fontAwosome($content:'\f105');
        position: absolute;
        left: 20px;
    }
}
.drawer-dropdown {
    .drawer-menu-item {
        &:before {
            @include fontAwosome($content:'\f107');
        }
    }
    &.open {
        .drawer-menu-item {
            &:before {
                @include fontAwosome($content:'\f106');
            }
        }
    }
}
.drawer-dropdown-menu {
    padding: 5px 10px 10px 5px;
}
.drawer-dropdown-menu-item {
    &:before {
        @include fontAwosome($content:'\f105');
    }
}

.-apply {
    background-image: linear-gradient(#ff5151 0%, #e80000 100%);
    color: #fff;
}