.price-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.3rem;
  tr {
    &:nth-child(2) {
      th {
        background-color: #dcebf6;
      }
    }
    &:nth-child(even) {
      background-color: #dcebf6;
    }
    &:nth-child(odd) {
      background-color: #f1f8fc;
    }
    &:first-child {
      background-color: #4398c6 !important;
      color: #fff;
    }
    &:nth-child(9) {
      th {
        background-color: #fffea6;
      }
    }
    &.bg-yellow01 {
      background-color: #fffea6;
    }
    &.bg-yellow02 {
      background-color: #fffed1;
    }
  }
  th,
  td {
    border: 1px solid #ccc;
    padding: 5px;
  }
  td {
    text-align: center;
  }
}

.price-table02 {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.3rem;
  margin-bottom: 15px;
  tr {
    &:first-child {
      background-color: #ddd !important;
    }
    &:nth-child(2) {
      background-color: #4398c6 !important;
      color: #fff;
    }
    &:nth-child(even) {
      background-color: #dcebf6;
    }
    &:nth-child(odd) {
      background-color: #f1f8fc;
    }
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 5px;
  }
  td {
    text-align: center;
  }
}

.note-table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ccc;
    padding: 5px 10px;
  }
}
