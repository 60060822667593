.sub-nav {
    margin-bottom: 30px;
    font-size: 1.4rem;
    li {
        &:first-child {
            background-color: $main-color;
            a {
                color: #fff;
                font-weight: bold;
            }
        }
        &.current {
            font-weight: bold;
        }
        a {
            text-decoration: none;
            //padding: 10px;
            display: block;
            padding: 15px;

            color: #333;
            border-bottom: 1px solid #ccc;
            span {
                margin-left:-1em;
                text-indent:1em;
                padding-left: 1rem;
            }
            &:before {
                @include fontAwosome($content: '\f105');

            }
        }
    }
}