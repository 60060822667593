@font-face {
    font-family: "YuGothic-M";
    font-weight: normal;
    src: local("YuGothic-Medium"),
    local("Yu Gothic Medium"),
    local("YuGothic-Regular");
}

@font-face {
    font-family: "YuGothic-M";
    font-weight: bold;
    src: local("YoGothic-Bold"),
    local("Yu Gothic");
}



html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    color: #333;
    font-family: "Hiragino Kaku Gothic ProN", -apple-system, blinkMacSystemFont, YuGothic-M, YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: "palt";
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    ::i-block-chrome, body {
        font-feature-settings: "pkna";
    }
}