.sidebar {

    li {
        list-style: none;
    }
    &-btn {
        margin-bottom: 10px;
    }
    &-banner {
        margin-bottom: 30px;
    }
    &-top__apply {
        h3 {
            font-size: 1.6rem;
        }
    };
    &-info {
        margin-bottom: 30px;
        p {
            font-size: 1.4rem;
        }
    }
    &-card {
        margin-bottom: 30px;
        h3 {
            line-height: 1.4;
            margin-bottom: 5px;
        }
        p {
            font-size: 1.3rem;
        }
    }
    &-over6month {
        margin-bottom: 30px;
        h3 {
            font-size: 2rem;
            line-height: 1.3;
            margin-bottom: 10px;
        }
        a {
            display: block;
            text-decoration: none;
            background-color: #FFFE78;
            padding: 10px;
            border-radius: $border-radius;
            font-weight: bold;
            color: #333;
            border: 1px solid #ccc;
        }
    }
    &-apply {

        &__catch {
            background-color: #FFFE7F;
            border: 1px solid #f00;
            border-radius: $border-radius;
            padding: 10px;
            font-weight: bold;
        }
    }
    &-pr {
        a {
            display: block;
            text-align: center;
        }
    }
}

@media screen and (min-width: $small-pc) {
    .sidebar {
        float: right;
        width: 230px;

    }

}
