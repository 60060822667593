ul.note {
    font-size: 1.2rem;
    li {
        list-style: none;
        text-indent: -1.2rem;
        margin-left: 1.2rem;
        margin-bottom: 5px;
        &:before {
            content: '※';
        }
    }
}

p.note {
    font-size: 1.2rem;
    text-indent: -1.2rem;
    margin-left: 1.2rem;
    margin-bottom: 1rem;
    &:before {
        content: '※';
    }
}

/*
&__text {
    text-indent: -1.2rem;
    margin-left: 1.2rem;
    margin-bottom: 1rem;
    &:before {
        content: '※';
    }
}
*/