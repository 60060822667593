dl {
  dt {
    font-weight: bold;
  }
}

// ul
.disc {
  li {
    list-style: disc;
    margin-left: 2.5rem;
  }
}
.circle {
  li {
    list-style: circle;
    margin-left: 2.5rem;
  }
}
.square {
  li {
    list-style: square;
    margin-left: 2.5rem;
  }
}
.no-style {
  list-style: none;
}
