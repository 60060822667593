.comp-table {
    width: 100%;
    font-size: 1.3rem;
    border-collapse: collapse;
    thead {
      tr {
        background-color: #439798;
        color: #fff;
      }
    }

    th, td {
        border: 1px solid #333;
        padding: 5px;
    }
    td {
        vertical-align: top;
    }
    tbody {
      tr {
        vertical-align: top;
      }
      th {
        background-color: #EFD5D5;
        text-align-last: left;
      }
    }
    table {
        border-collapse: collapse;
        th {
            background-color: #ddd !important;
            color: #333 !important;
        }
    }
    .note-num-list {
      list-style: none;
      margin: 0 !important;
    }
    .note-num-list li {
      text-indent: -1.5rem;
      padding-left: 1.5rem;
    }

    .brackets-num-list {
      margin: 0 !important;
      list-style: none;
    }
    .brackets-num-list li {
      list-style-image: none !important;
      text-indent: -1.5rem;
      padding-left: 1.5rem;
    }

    .inner-list {
      list-style: disc;
      margin: 0 0 0 15px !important;
    }
    .inner-list li {
      text-indent: 0;
      padding-left: 0;
      margin: 0 0 10px 0!important;
    }
    .normal-list {
      margin-left: 2rem;
    }
    em {
      font-style: normal;
    }
}

.faq-link {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    li {
        width: 50%;
        margin-bottom: 10px;
        &:before {
            @include fontAwosome($content:'\f105');
        }
        &.current {
            font-weight: bold;
            color: $main-color;
        }
        a {
            text-decoration: none;
            color: #333;
        }
    }
}

.faq-table {
    tr {
        vertical-align: top;
        &:first-child {
            td:first-child {
                color: #0199CB;
                font-weight: bold;
            }
        }
        &:last-child {
            td:first-child {
                color: #f00;
                font-weight: bold;
            }
        }
    }
}

.faq-inner-table {
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 20px;
  th {
    background-color: #ddd;
  }
  th,
  td {
    padding: 10px;
    border: 1px solid #ccc;
  }
}

