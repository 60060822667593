.case {
    &__text {
        background-color: #FFFEA6;
        padding: 20px 10px 10px;
        border-radius: $border-radius;
    }
    &__num {
        font-weight: bold;
        border-bottom: 1px dotted #ccc;
        margin-bottom: 10px;
    }
    &__insurance {
        font-weight: bold;
        color: #f00;
        font-size: 1.8rem;
    }
    &__image {
        text-align: center;
    }
}

.case-table {
    width: 100%;
    border-collapse: collapse;
    th, td {
        border: 1px solid #ccc;
        padding: 10px;
    }
    th {
        background-color: #4398C6;
        color: #fff;
    }
    tr:nth-child(2) {
        td {
            font-weight: bold;
        }
    }
    tr:nth-child(6) {
        td {
            text-align: center;
        }
    }
}


@include screen-small-pc {
    .case {
        display: flex;
        &__image {
            margin-right: 20px;
        }
    }
}