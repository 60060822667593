@import "foundation/_mixin.scss";
@import "foundation/_ress.scss";
@import "foundation/_variables.scss";
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_sidebar.scss";
@import "object/component/_article.scss";
@import "object/component/_back-link.scss";
@import "object/component/_breadcrumb.scss";
@import "object/component/_btn.scss";
@import "object/component/_drawer.scss";
@import "object/component/_font.scss";
@import "object/component/_footer-apply.scss";
@import "object/component/_internal-link.scss";
@import "object/component/_list.scss";
@import "object/component/_nav.scss";
@import "object/component/_next-link.scss";
@import "object/component/_note.scss";
@import "object/component/_page-header.scss";
@import "object/component/_sub-nav.scss";
@import "object/project/_comp.scss";
@import "object/project/_furnish.scss";
@import "object/project/_medical.scss";
@import "object/project/_price.scss";
@import "object/project/_service.scss";
@import "object/project/_top.scss";
@import "object/project/_utility.scss";
@import "object/utility/_lead.scss";
@import "object/utility/_list.scss";
@import "object/utility/_margin.scss";





body {
    line-height: 1.7;
}
.container {
    width: 94%;
    margin: 0 auto;
    max-width: 1024px;
}
.container:after {
    content: "";
    display: table;
    clear: both;
}


@media screen and (min-width: $small-pc) {
    .main {
        width: 770px;
        float: left;
    }
}

.main {
    line-height: 1.7;
}

$title-red: #F00;
$title-large: 2.4rem;



.title {
    margin-bottom: 10px;
    &--red {
        color: $title-red;
    }
    &--large {
        font-size: $title-large
    }
}


.-red {
    color: #f00;
}
.-blue {
    color: #039;
}
.-normal {
    font-weight: normal;
}
.-bold {
    font-weight: bold;
}
.-small {
    font-size: 1.2rem;
}
.-large {
    font-size: 1.8rem;
}


.max-image {
    width: 100%;
    height: auto;
}

.t-left {
    text-align: left;
}
.t-center {
    text-align: center;
}

@media screen and (min-width: $small-pc) {
    .sp-apply {
        display: none;
    }
}


