.medical-table {
  width: 100%;
  th,
  td {
    display: block;
    width: 100%;
    border-bottom: 1px solid #ccc;
  }
  th {
    background-color: #eee;
    line-height: 1.3;
    padding: 10px;
  }
  td {
    padding: 15px 0;
  }
  li {
    list-style: none;
    &::before {
      content: '●';
    }
  }
}

.room-table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  tr {
    vertical-align: top;
  }
  th,
  td {
    display: table-cell;
  }
  th {
    background-color: #b1cffb;
    padding: 5px;
  }
  td {
    padding: 5px;
    &:first-child {
      width: 20%;
      white-space: nowrap;
    }
    &:nth-child(2) {
      width: 5%;
    }
    &:nth-child(3) {
      width: 75%;
    }
  }
}

.operation-table {
  width: 100%;
  tr {
    &:nth-child(even) {
      background-color: #dae9fd;
    }
    &:nth-child(odd) {
      background-color: #ddd;
    }
  }
  th,
  td {
    display: table-cell;
  }
  th {
    background-color: #b1cffb;
    &:first-child {
      width: 20%;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      width: 40%;
    }
  }
  td {
    width: auto;
    padding: 10px;
  }
}

.medical-note_newyork,
.medical-note_honolulu {
  li {
    &:nth-child(n + 3) {
      &::before {
        content: '';
      }
    }
  }
}

.medical-note_guam {
  li {
    &:nth-child(1) {
      font-size: 1.4rem;
      &::before {
        content: '';
      }
    }
  }
}

@include screen-small-pc {
  .medical-table {
    th {
      text-align: left;
      br {
        display: none;
      }
    }
  }
}
