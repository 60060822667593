.nav {
    li {
        list-style: none;
    }
    &__first-list {
        display: flex;
        justify-content: space-around;
    }
    &__first-item {
        flex-basis: 16.666%;
        border-right: 1px solid #ccc;
        &:last-child {
            border: 0;
        }
    }
    &__first-link {
        text-decoration: none;
        font-weight: bold;
        padding: 12px 10px;
        display: block;
        background-color: #eee;
        text-align: center;
        color: #444;
    }
    &__second-list {
        display: none;
    }
}