.btn {
  display: inline-block;
  position: relative;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  font-weight: bold;
  padding: 15px;
  color: #333;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.28);
  width: 100%;
  border: 1px solid #ddd;
  &:hover {
    opacity: 0.7;
  }
  &__description {
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.4;
    display: block;
  }
  &--apply {
    font-size: 2.2rem;
    color: #fff;
    background-image: -webkit-linear-gradient(#ff5151 0%, #e80000 100%);
    background-image: linear-gradient(#ff5151 0%, #e80000 100%);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.66);
    margin-bottom: 10px;
    line-height: 1.4;
    text-align: left;
    .apply__arrow {
      //float: left;
      //font-size: 4.5rem;
      //width: 18%;
      //padding-top: 8px;
    }
    .apply__text {
      //float: right;
      //width: 82%;
    }
    .pc-br {
      display: none;
    }
  }

  &--yellow {
    font-size: 1.6rem;
    color: #fff;
    background-image: -webkit-linear-gradient(#fefed6 0%, #f7f791 100%);
    background-image: linear-gradient(#fefed6 0%, #f7f791 100%);
    line-height: 1.6;
    text-align: left;
  }

  &--attention {
    background-color: #f00;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    padding: 3px;
    font-size: 1.2rem;
    margin-top: 5px;
  }
}

@media screen and (min-width: $small-pc) {
  .apply-btn-wrapper {
    display: flex;
    justify-content: center;
  }
  .btn {
    &--apply {
      //padding-left: 60px;
      //padding-right: 60px;
      .pc-br {
        display: block;
      }
    }
  }
}
