.article {
    &__h1 {
        font-size: 2.2rem;
        line-height: 1.3;
        margin: 10px 0 20px;
        span {
            font-weight: normal;
            font-size: 1.6rem;
        }
    }
    &__h2 {
        background-color: $main-color;
        border-radius: $border-radius;
        color: #fff;
        padding: 10px;
        font-size: 1.8rem;
        margin-bottom: 20px;
    }
    &__h3 {
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
    }
    &__h4 {
    }
    &__h5 {
    }
    p, ul, ol {
        margin-bottom: 1.5rem;
    }
    section {
        margin-bottom: 50px;

        section {
            margin-bottom: 35px;
        }
    }
}

.signature {
    text-align: right;
    margin-bottom: 10px;
    font-size: 1.3rem;
}

