.corporate-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin-bottom: 20px;
    width: 100%;
    th, td {
        border: 1px solid #ccc;
        padding: 5px;
    }
    th {
        width: 20%;
        background-color: #eee;
        font-weight: normal;
        font-size: 1.4rem;
    }
    td {
        width: 80%;
    }
}

.sitemap {
    li {
        font-weight: bold;
        margin-bottom: 30px;
        list-style: none;
        &:before {
            @include fontAwosome($content: '\f105');
        }
        a {
            color: #333;
            text-decoration: none;
        }
        ul {
            margin-left: 1.5rem;
        }
        li {
            font-weight: normal;
            margin: 10px 0 0;
        }
    }
}