.footer {
    max-width: 1024px;
    margin: 30px auto 0;
    font-size: 1.4rem;
    li {
        list-style: none;
    }
    ul {
        background-color: #eee;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        li {
            margin: 0 0 10px;
            width: 50%;
            &:first-child {
                width: 100%;
            }
            a {
                color: #333;
                text-decoration: none;
                &:before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: '\f0da';
                    margin-right: 5px;
                }
            }
        }
    }
    &__info {
        text-align: center;
        font-size: 1.3rem;
        margin-top: 30px;
    }
    &__copyright {
        font-size: 1.2rem;
        margin: 20px 0 30px;
        border-top: 1px solid #ccc;
        text-align: center;
        padding-top: 20px;
    }
}

@media screen and (min-width: $small-pc) {
    .footer {
        ul {
            li {
                width: 25%;
            }
        }
    }
}

.unique-id {
    margin-top: 15px;
}