@charset "UTF-8";
// 構成案
.BlockName {
    @at-root {
        $_localVariable: 10px;

        & {
        }

        .BlockName__element1 {
        }

        .BlockName__element2 {
        }
    }
}




.kv {
    margin-top: 20px;
    &__title {
        //color: #fff;
        font-size: 3.2rem;
        //text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
        border-bottom: 3px solid #fff;
        display: inline-block;
        margin-bottom: 10px;
        line-height: 1.3;
    }
    &__sub-title {
        //color: #fff;
        //text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
        margin-bottom: 20px;
        span {
            color: #f00;
            text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);;
            font-size: 2rem;
        }
    }
}

@media screen and (min-width: $small-pc) {
    .kv {
        &__title {
            color: #fff;
            font-size: 3.2rem;
            text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
            border-bottom: 3px solid #fff;
            display: inline-block;
            margin-bottom: 10px;
        }
        padding: 10px;
        background: url("../images/index_kv.jpg") no-repeat;
        height: 261px;
        &__sub-title {
            color: #fff;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
            span {
                color: #f00;
                text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);;
                font-size: 2rem;
            }
        }
        &-inner {
            padding: 15px 35px 5px;
        }
        &__text {
            text-align: center;
        }
    }
}


.point {
    margin-bottom: 10px;
    &__item {
        padding: 15px;
        margin-bottom: 10px;
        text-align: center;
        background: rgba(251,255,145,1);
        background: -moz-linear-gradient(top, rgba(251,255,145,1) 0%, rgba(225,230,87,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251,255,145,1)), color-stop(100%, rgba(225,230,87,1)));
        background: -webkit-linear-gradient(top, rgba(251,255,145,1) 0%, rgba(225,230,87,1) 100%);
        background: -o-linear-gradient(top, rgba(251,255,145,1) 0%, rgba(225,230,87,1) 100%);
        background: -ms-linear-gradient(top, rgba(251,255,145,1) 0%, rgba(225,230,87,1) 100%);
        background: linear-gradient(to bottom, rgba(251,255,145,1) 0%, rgba(225,230,87,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbff91', endColorstr='#e1e657', GradientType=0 );
    }

    &__text {
        font-weight: bold;
        font-size: 2rem;
        color: #f00;
        line-height: 1.4;
        span {
            font-weight: normal;
            font-size: 1.8rem;
        }

    }
}

@media screen and (min-width: $small-pc) {
    .point {
        align-items: center;
        &__item {
            float: left;
            width: 33.3%;
            height: 114px;
            border-right: 1px solid #ccc;
            &.twoline {
                padding-top: 3rem;
            }
        }
    }
}


.use {
    &__title {
        background-color: #FFFEA6;
    }
}


.bgBox {
    & {
        margin-bottom: 30px;
        //background-color: #F2FEF0;
        padding: 20px;
    }
    &__title {
        font-size: 2rem;
        padding: 10px;
        margin-bottom: 15px;
        border-bottom: 3px solid #eee;
        &--attention {
            color: #f00;
        }
        &--small {
            font-size: 1.6rem;
        }
    }
    &--border {
        border: 1px solid #ccc;
    }
    &__green {
        background-color: #f2fef0;
    }
}


.index-point {
    li {
        list-style: none;
    }
    > li {
        width: 100%;
        border: 1px solid #ccc;
        padding: 5px;
        margin-bottom: 15px;

        .inner {
            padding: 0 15px 15px;
        }
        &.know {
            background: rgba(208, 114, 114, .1);
            border: 2px solid #D07274;
            h3 {
                background-color: #D07274;
            }
        }
        &.understand {
            border: 2px solid #B0DD58;
            background: rgba(176, 221, 221, .1);
            h3 {
                background-color: #B0DD58;
            }
        }
        &.check {
            border: 2px solid #4AA7DB;
            background: rgba(74, 167, 167, .1);
            h3 {
                background-color: #4AA7DB;
            }
        }
        &.enter {
            border: 2px solid #EA514A;
            background: rgba(234, 81, 81, .1);
            h3 {
                background-color: #EA514A;
            }
        }
    }
    h3 {
        font-size: 2rem;
        padding: 5px 10px;
        margin-bottom: 10px;
        color: #fff;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    }
    h4 {
        padding-bottom: 5px;
        border-bottom: 1px solid #aaa;
        margin-bottom: 10px;
    }
    p {
        font-family: 'Trebuchet MS';
        color: #aaa;
        font-size: 1.4rem;
        font-style: italic;
    }
    a {
        text-decoration: none;
        color: #333;
        &:before {
            content: '\f0da';
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            margin-right: 5px;
        }
        &:hover {
            text-decoration: underline;
            font-weight: bold;
        }
    }
}
@media screen and (min-width: $small-pc) {
    .index-point {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > li {
            width: 49%;
        }
    }

}




