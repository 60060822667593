.footer-apply {
    //pointer-events: none;
    position: fixed;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: 1s ease;
    width: 100%;


    &.is-active {
        opacity: 1;
    }

    .btn--apply {
        margin-bottom: 0;
    }
    .apply__arrow {
        width: 10%;
    }
    .apply__text {
        width: 90%;
    }

}
@media screen and (min-width: $small-pc) {
    .footer-apply {
        display: none;
    }
}